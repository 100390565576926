import * as React from 'react';
import { Grid, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import InstagramImage from './icons8-instagram-48.png';
import BlackandWhiteInstagramImage from './black&white_icons8-instagram-48.png'
import FacebookImage from './icons8-facebook-48.png';
import BlackandWhiteFacebookImage from './black&white_icons8-facebook-48.png';
import EmailImage from './icons8-email-48.png'
import BlackandWhiteEmailImage from './black&white_icons8-email-48.png'
import PhoneImage from './icons8-phone-48.png'
import BlackandWhitePhoneImage from './black&white_icons8-phone-48.png'
import WhatsappImage from './icons8-whatsapp-48.png'
import BlackandWhiteWhatsappImage from './black&white_icons8-whatsapp-48.png'
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ImageMenuButton from './imagemenubutton/ImageMenuButton';

const IconRow = (shopInfo) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container flexDirection='row' justifyContent="center">
            <Grid item>
            {((shopInfo.properties.instagram === '') || (shopInfo.properties.instagram === undefined)) ? (<div><img src={BlackandWhiteInstagramImage} alt="Instagram logo" /></div>) : (<a href={shopInfo.properties.instagram} target="_blank" rel="noreferrer">
                <img src={InstagramImage} alt="Instagram logo" />
            </a>)}
            </Grid>
            <Grid item>
            {((shopInfo.properties.facebook === '') || (shopInfo.properties.facebook === undefined)) ? (<div><img src={BlackandWhiteFacebookImage} alt="Facebook logo" /></div>) : (<a href={shopInfo.properties.facebook} target="_blank" rel="noreferrer">
                <img src={FacebookImage} alt="Facebook logo" />
            </a>)}
            </Grid>
            <Grid item>
            {((shopInfo.properties.email === '') || (shopInfo.properties.email === undefined)) ? (<div><img src={BlackandWhiteEmailImage} alt="Email logo" /></div>) : (<a href={shopInfo.properties.email} target="_blank" rel="noreferrer">
                <img src={EmailImage} alt="Email logo" />
            </a>)}
            </Grid>
            <Grid item>
            {/* {((shopInfo.properties.phone === '') || (shopInfo.properties.phone === undefined)) ? (<div><img src={BlackandWhitePhoneImage} alt="Phone logo" /></div>) : (<a href={`tel:${shopInfo.properties.phone}`} > */}
                {/* <img src={PhoneImage} alt="Phone logo" onClick={handleClick}/> */}
                <ImageMenuButton  shopProps={shopInfo.properties}/>
            {/* </a>)} */}
            </Grid>
            {/* <Grid item>
            {((shopInfo.properties.phone === '') || (shopInfo.properties.phone === undefined)) ? (<div><img src={BlackandWhiteWhatsappImage} alt="Whatsapp logo" /></div>) : (<a href={`tel:${shopInfo.properties.phone}`} >
                <img src={WhatsappImage} alt="Whatsapp logo" />
            </a>)}
            </Grid> */}
        </Grid>
    );
}

export default IconRow;
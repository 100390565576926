import { db } from "../../components/auth/firebase";
import { doc, setDoc, getDoc, collection, getDocs } from 'firebase/firestore';
import { auth } from "../../components/auth/firebase";
import { ex } from "@fullcalendar/core/internal-common";


export interface UserInfo {
    address: string
    email: string;
    phone_number: string;
    shopOwned: string;
}

export interface UserData {
    id: string;
    role: string;
}

// Function to store user info
export async function storeUserInfo(userId: string, userInfo: object): Promise<void> {
    try {
        await setDoc(doc(db, "users", userId), userInfo);
        console.log("User information successfully stored!");
    } catch (error) {
        console.error("Error storing user information: ", error);
    }
    }

// Function to get user info
export async function getUserInfo(): Promise<UserInfo | null> {
    try {
        if (auth.currentUser) {
            const docSnap = await getDoc(doc(db, "users", auth.currentUser.uid));
            if (docSnap.exists()) {
                console.log("User found");
            } else {
                await storeUserInfo(auth.currentUser.uid, {"email": auth.currentUser.email});
                console.log("User Created!");
            }
            const userData: UserInfo = {
                address: docSnap.data().address,
                email: docSnap.data().email,
                phone_number: docSnap.data().phone_number,
                shopOwned: docSnap.data().shopOwned
            }
            return userData;
        }
    } catch (error) {
        console.error("Error getting user information: ", error);
        return null;
    }
}


export async function getUserDataCollection(): Promise<UserData> {
    try {
        const userId = auth.currentUser.uid
        const subcollectionRef = collection(db, 'users', userId, 'data');
        const subcollectionSnapshot = await getDocs(subcollectionRef);
        const subcollectionData: UserData[] = subcollectionSnapshot.docs.map(doc => ({
        id: doc.id,
        role: doc.data().role
        }));
        return subcollectionData[0];
    } catch (error) {
        console.error("Error getting user data collection information: ", error);
        return null;
    }
}
import React, { useState, useEffect, useRef, useCallback  }  from 'react';
import './HomePage.css';
import Calendar from '../../components/calendar/Calendar';
import ResponsiveAppBar from '../../components/appbar/Appbar';
import { isMobile } from 'react-device-detect';
import ShopCard from './ShopCard';
import { loadNailSalondata, NailSalonsGeo } from '../../Pages/Map/nail_salons';
import { fetchBucketImage } from '../../components/auth/Bucketimages';
import { Box } from '@mui/system';


const HomePage: React.FC = () => {
  const [AllShops, setAllShops] = useState<NailSalonsGeo | null>(null);

  const api_key = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`

  useEffect(() => {
    void loadNailSalondata().then(
      data => setAllShops(data)).then(
        
      );
  }, []);
  
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  useEffect(() => {
    fetchBucketImage(setImageUrl, 'gs://lilac-4ec8e.appspot.com/1200px-Polished_purple_nails_with_nail_art_on.jpg');
  }, [AllShops]);

  return (
      <div className="homepage-container" style={{flexGrow: '1', flexDirection: 'column', justifyContent: 'space-between', background: "#fff8f5", textAlign: 'center'}}>
          <ResponsiveAppBar/>
        <ol style={{ listStyle: 'none' }}>
        {AllShops ? AllShops.features.map((shop) => (
          <li key={shop.id}>
            <div style={{marginTop: 10, marginBottom: 10}}>
          <ShopCard name={shop.properties.name} image={imageUrl} rating={4.5} distance={1} shopID={`${shop.id}`}/>
            </div>
          </li>
          
        ))
        :
        <div>loading...</div>}
        </ol>
        </div>
        
  );
};

export default HomePage;

import React, {memo} from 'react';
import {Feature, Point, Position} from 'geojson';
import {NailSalonFeatureProps} from '../nail_salons';
import AddressLink from '../components/AddressLink';
import { useNavigate } from 'react-router-dom';


type InfowindowContentProps = {
  features: Feature<Point>[];
};

const numFmt = new Intl.NumberFormat();

export const InfoWindowContent = memo(({features}: InfowindowContentProps) => {
  const navigate = useNavigate();

  if (features.length === 1) {
    const f = features[0];
    const props = f.properties! as NailSalonFeatureProps;

    return (
      <div>
        <h4>{props.name}</h4>
        <p>
          <a href={navigateToShop(props.address)} target="_blank">
            Navigate to
          </a>
          <br />
          <AddressLink address={props.address} label={props.name} />
          <br />
          <a onClick={() => navigate(`/shop/search?shopID=${f.id}`)} target="_blank">
            Shop page
          </a>
        </p>
      </div>
    );
  }

  return (
    <div>
      <h4>{numFmt.format(features.length)} features. Zoom in to explore.</h4>

      <ul>
        {features.slice(0, 5).map(feature => {
          const props = feature.properties! as NailSalonFeatureProps;

          return (
            <li key={feature.id}>
              <a  onClick={() => navigate(`/shop/search?shopID=${feature.id}`)} target="_blank">
                {props.name}
              </a>
            </li>
          );
        })}

        {features.length > 5 && (
          <li>and {numFmt.format(features.length - 5)} more.</li>
        )}
      </ul>
    </div>
  );
});

function navigateToShop(address: string) {
  const encodedAddress = encodeURIComponent(address);
  return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
}

function getShopPageUrl(id: string) {
  return `/shop/search?shopID=${id}`;
}
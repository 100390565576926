import React, { useState } from 'react';
import { Button, ToggleButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';


const ButtonGrid: React.FC = () => {
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

  // Custom styled Button to ensure all buttons look alike
    const StyledButton = styled(Button)(({ theme }) => ({
        flex: 1,
        padding: theme.spacing(1, 2),
    }));

    // Custom styled ToggleButton to match the other buttons
    const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
        flex: 1,
        padding: theme.spacing(1, 2),
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            },
        },
    }));

    return (
        <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="row"
        flexWrap="wrap"
        sx={{ gap: 2, padding: 2 }}
        >
        <Button
            variant="contained"
            sx={{
            flex: '1 1 auto',
            minWidth: 100,
            paddingY: 2,
            textAlign: 'center',
            background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
            }}
        >
            Shop Settings
        </Button>
        <Button
            variant="contained"
            sx={{
            flex: '1 1 auto',
            minWidth: 100,
            paddingY: 2,
            textAlign: 'center',
            background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
            }}
        >
            Services
        </Button>
        <ToggleButton
        value="check"
        selected={isToggled}
        color='warning'
        onChange={() => {
            setIsToggled(!isToggled);
        }}
>
        Delete Appointments
        </ToggleButton>
        </Box>
    );
    };

export default ButtonGrid;


// // Custom styled Box to act as a container for our buttons
// const ButtonContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   justifyContent: 'space-between',
//   gap: theme.spacing(2),
//   width: '100%',
//   maxWidth: '600px',
//   margin: '0 auto',
// }));

// // Custom styled Button to ensure all buttons look alike
// const StyledButton = styled(Button)(({ theme }) => ({
//   flex: 1,
//   padding: theme.spacing(1, 2),
// }));

// // Custom styled ToggleButton to match the other buttons
// const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
//   flex: 1,
//   padding: theme.spacing(1, 2),
//   '&.Mui-selected': {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
// }));

// // Interface for our component props
// interface ButtonGridProps {
//   onButton1Click: () => void;
//   onButton2Click: () => void;
//   onToggleClick: (isActive: boolean) => void;
// }

// const ButtonGrid: React.FC<ButtonGridProps> = ({
//   onButton1Click,
//   onButton2Click,
//   onToggleClick
// }) => {
//   const [isToggleActive, setIsToggleActive] = useState(false);

//   const handleToggleClick = () => {
//     const newToggleState = !isToggleActive;
//     setIsToggleActive(newToggleState);
//     onToggleClick(newToggleState);
//   };

//   return (
//     <ButtonContainer>
//       <StyledButton 
//         variant="contained" 
//         color="primary" 
//         onClick={onButton1Click}
//       >
//         Button 1
//       </StyledButton>
//       <StyledButton 
//         variant="contained" 
//         color="primary" 
//         onClick={onButton2Click}
//       >
//         Button 2
//       </StyledButton>
//       <StyledToggleButton
//         value="check"
//         selected={isToggleActive}
//         onChange={handleToggleClick}
//         color="primary"
//       > Del Appointment
//         {/* {isToggleActive ? 'On' : 'Off'} */}
//       </StyledToggleButton>
//     </ButtonContainer>
//   );
// };

// export default ButtonGrid;
import React from 'react';

/**
 * 
 * 
 */

export const NailPolishSvg = () => ( 
	<svg fill="#f805c3" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
		width="50px" height="50px" viewBox="0 0 174.709 174.71"
		xmlSpace="preserve">
	<g>
		<g>
			<path d="M126.228,90.189l-9.193-3.414c-4.207-1.555-11.332-2.837-15.819-2.837H73.495c-4.487,0-11.614,1.282-15.821,2.837
				l-9.191,3.414c-4.207,1.56-6.936,6.446-6.058,10.846l13.13,65.663c0.879,4.4,5.278,8.012,9.771,8.012h44.061
				c4.487,0,8.887-3.611,9.77-8.012l13.131-65.663C133.162,96.636,130.441,91.749,126.228,90.189z M114.748,121.16
				c0,2.244-1.836,4.083-4.087,4.083H64.047c-2.246,0-4.083-1.839-4.083-4.083v-13.267c0-2.251,1.837-4.09,4.083-4.09h46.614
				c2.251,0,4.087,1.85,4.087,4.09V121.16z"/>
			<polygon points="101.215,0 73.495,0 65.325,75.768 109.385,75.768 		"/>
		</g>
	</g>
	</svg> 
)
import React, { useState } from 'react';
import { useMap, AdvancedMarker } from '@vis.gl/react-google-maps';
import { MyLocationSvg } from './my-location';

export const JumpToLocationButton = () => {
    const map = useMap();
    const [isLocating, setIsLocating] = useState(false);

    const handleClick = () => {
        setIsLocating(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                map.panTo({ lat: latitude, lng: longitude });
                map.setZoom(15);
                setIsLocating(false);
            },
            (error) => {
                console.error('Error getting location:', error);
                setIsLocating(false);
            }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setIsLocating(false);
        }
    };
    return (
        <div style={{padding: '10px'}}>
        <button 
            onClick={handleClick} 
            disabled={isLocating}
            style={{
            padding: '10px',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer',
            }}
        >
            <MyLocationSvg />
        </button>
        </div>
    );
}
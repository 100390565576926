import {FeatureCollection, Point} from 'geojson';
import { doc, setDoc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from "../../components/auth/firebase";



export type NailSalonFeatureProps = {
  name: string;
  address: string;
  instagram?: string;
  facebook?: string;
  email?: string;
  phone?: string;
};

export type NailSalonFeature = {
  type: 'Feature';
  id: string;
  geometry: Point;
  properties: NailSalonFeatureProps;
};
export type NailSalonsGeo = FeatureCollection<Point, NailSalonFeatureProps>;

export async function loadNailSalondata(): Promise<NailSalonsGeo> {
  try {
    const shopsCollection = collection(db, 'shops');
    const shopsSnapshot = await getDocs(shopsCollection);
    const shopsList = shopsSnapshot.docs.map(doc => ({
      id: doc.id,
      address: doc.data().address,
      geometry: doc.data().geometry,
      name: doc.data().name,
      instagram: doc.data().instagram,
    }));

    const nailSalons: NailSalonsGeo = {
      type: 'FeatureCollection',
      features: shopsList.map(shop => ({
        type: 'Feature',
        id: shop.id,
        geometry: {
          type: 'Point',
          coordinates: [shop.geometry._long, shop.geometry._lat]
        },
        properties: {
          name: shop.name,
          address: shop.address,
          instagram: shop.instagram
        }
      }))
    };
    return nailSalons;
  } catch (error) {
    console.error("Error fetching shops: ", error);
    return null;
  }
}


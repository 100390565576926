import React, { useEffect, useState } from 'react';
import { Calendar, formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import axios, { AxiosResponse } from "axios";
import SearchBox from '../searchbox/SearchBox';
import { isMobile } from 'react-device-detect';
import { getAppointmentInfo } from '../../resources/appointmentsDB/AppointmentsDB';
import './Calendar.css'


interface LilacCalendarProps {
  shopId: string
  // Not: shopData: string
}

export interface Event {
  id: string,
  client_id: string,
  shop_id: string,
  title: string,
  start: Date,
  end: Date,
  service: string,
  allDay: boolean,
  status: string,
}

const LilacCalendar: React.FC<LilacCalendarProps> = (shopData) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shopId, setShopId] = useState(shopData.shopId);
  const [currentEvents, setCurrentEvents] = useState<Event[]>([]);
  const [userEvents, setUserEvents] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>([]);
  
  const [selectedOptions, setSelectedOptions] = useState(shopData.shopId);

  
  const [leftCalendarButtons, setLeftCalendarButtons] = useState('prev,next');

  const [centerCalendarButtons, setCenterCalendarButtons] = useState(['prev', 'next', 'today', 'dayGridMonth']);
  const [rightCalendarButtons, setRightCalendarButtons] = useState(['prev', 'next', 'today', 'dayGridMonth']);
  
  

  var user_events


  useEffect(() => {
    fetchEvents();
    if (!isMobile) {
      setLeftCalendarButtons('prev,next,today')
    }
  }, []);

  useEffect(() => {
    setCurrentEvents([...userEvents,...selectedEvents])
  }, [userEvents,selectedEvents]);

  const fetchEvents = async () => {
    setIsLoading(true);
    const events_list = await getAppointmentInfo(shopData.shopId)
    setCurrentEvents(events_list)
    setIsLoading(false);

  }

  const handleEventDidMount = (info) => {
    if (info.view.type === 'dayGridMonth') {
      info.el.style.display = 'none';
    }
  };

  


  const renderSidebar = () => {
    return (
      // <div className='demo-app-sidebar'>
        <div className='demo-app-sidebar-section'>
          {/* <h2>All Events ({currentEvents.length})</h2>
          <ul>
            {currentEvents.map(renderSidebarEvent)}
          </ul> */}
        </div>
      // </div>
    )
  }

  const createOwnerEvent = async (selectInfo: any, calendarApi: any) => {
    const res: AxiosResponse = await axios.post(`${process.env.REACT_APP_API_ADDRESS}/api/appointments`, {
      'start_time': selectInfo.start,
      'end_time': selectInfo.end,
      'service': null,
    },
    {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true, // Include cookies in the request
    })

    if (res.status === 200) {
      fetchEvents()
    }
    else {
      alert('Failed to create event')
    }
    
  }

  const dayCellDidMount = (arg) => {
    const dateNumber = arg.el.querySelector('.fc-daygrid-day-number');
    if (dateNumber) {
      dateNumber.style.pointerEvents = 'none';
      dateNumber.style.cursor = 'default';
    }
    const { date, el } = arg;
    const dayEvents = currentEvents.filter(event => 
      event.start.toDateString() === date.toDateString()
    );

    if (dayEvents.length > 0) {
      const allAvailable = dayEvents.every(event => event.status == 'available');
      const someAvailable = dayEvents.some(event => event.status == 'available');

      if (allAvailable) {
        el.classList.add('fc-day-all-available');
      } else if (someAvailable) {
        el.classList.add('fc-day-some-available');
      } else {
        el.classList.add('fc-day-none-available');
      }
    }
  };

  const handleDateClick = (arg: any) => {
    console.log(arg)

  }

  const handleDateSelect = async (selectInfo: any) => {
    const calendarEvents =  selectInfo.view.calendar.getEvents()
    let eventOnDate = false
    let selectedDate = ''
    if ("dateStr" in selectInfo) {
      selectedDate = selectInfo.dateStr
    }
    else {
      selectedDate = selectInfo.startStr.split('T')[0]
    }
    for (let i = 0; i < calendarEvents.length; i++) {
      if (calendarEvents[i].startStr.split('T')[0] === selectedDate) {
        eventOnDate = true
        break
      }
    }
    if (eventOnDate == false) {
      return
    }
    if (selectInfo.view.type === 'dayGridMonth') {
      let calendarApi = selectInfo.view.calendar
      // calendarApi.change ={{
      //   left: 'prev,next,today',
      //   center: 'title',
      //   right: 'dayGridMonth'
      // }}
      calendarApi.changeView('listDay', selectInfo.startStr);

      return
    }
    // let confirmSlot = window.confirm('Would you like to add available hours between \n' + new Date(selectInfo.startStr).toLocaleString() + '\n to \n' + new Date(selectInfo.endStr).toLocaleString())
    // let calendarApi = selectInfo.view.calendar

    // calendarApi.unselect() // clear date selection

    // // get difference in milliseconds
    // var appointmentLength = (new Date(selectInfo.endStr).getTime() - new Date(selectInfo.startStr).getTime())
    // // convert to hours
    // appointmentLength = appointmentLength / 1000 / 60 / 60
    // // convert to number of 15 minute blocks
    // appointmentLength = appointmentLength * 4

    // if (confirmSlot) {
    //   if (sessionStorage.getItem('user_type') === 'owner') {
    //     createOwnerEvent(selectInfo, calendarApi)
        
    //   }
    //   // TODO: implement client modifying appointments
    //   else if (sessionStorage.getItem('user_type') === 'client') {
    //     alert('You are a client')
    //     return
    //   }
    
    // }
  }

  const handleMouseHover = (hoverInfo: any) => {
    if (hoverInfo.event.extendedProps.available)
      return false
    else
      return false
  }

  const handleEventClick = async (clickInfo: any) => {
    let calendarApi = clickInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return
    }
    if (sessionStorage.getItem('user_type') === 'client') {
      if (!clickInfo.event.extendedProps.available) {
        return 
      }
      alert('Meeting set')
    }
    if (sessionStorage.getItem('user_type') === 'owner') {
      if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        try{
          const res = await axios.delete(`${process.env.REACT_APP_API_ADDRESS}/api/appointments/${clickInfo.event.id}`, {
            withCredentials: true, // Include cookies in the request
          })
          if (res.status === 200) {
            clickInfo.event.remove()
          }
        }
        catch (error) {
            alert('Failed to delete event')
        }
      }
    }
  }

  const handleEventDisplay = (event: any) => {
    return
  }


  



  const handleEvents = (events: any) => {
    // setCurrentEvents(events)
  }


  const renderEventContent = (eventInfo: any) => {
    let calendarApi = eventInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return (
        null 
      )
    }
    else{
    return (
      <>
        <b>{eventInfo.timeText}</b> 
        <br />
        <i>{eventInfo.event.title}</i>
        <br />
        <i>{eventInfo.event.extendedProps.status}</i>
        {/* <i> {eventInfo.event.shop_id}</i> */}
      </>
    )
    }
  }

  const renderSidebarEvent = (event: any) => {
    return (
      <li key={event.id}>
        <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
        <i> {event.title}</i>
      </li>
    )
  }

  

  if (isLoading) {
    return <div>Loading events...</div>;
  }
    return (
      <div className='lilac-calendar'>
        {/* {renderSidebar()} */}
        {/* {(sessionStorage.getItem('user_type') === 'client') ? <SearchBox MultiSelect={true} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} setSelectedEvents={setSelectedEvents}/> : null} */}
        
        <div className='demo-app-main'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'today,dayGridMonth'
            }}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            // selectAllow={handleSelectAllow} // Allow selection based on custom logic
            selectMirror={true}
            dayMaxEvents={true}
            events={currentEvents}
            slotDuration={{ minutes: 30 }}
            // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
            dateClick={handleDateSelect}
            select={handleDateSelect}

            eventContent={renderEventContent} // custom render function
            dayMaxEventRows={0} // This prevents the "+X more" link from appearing

            eventClick={handleEventClick}
            eventMouseEnter={handleMouseHover}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            slotMinTime="08:00:00" // Show only from 8:00 AM
            slotMaxTime="22:00:00" // Show only until 10:00 PM
            slotLabelInterval={{ hours: 1 }} // Show labels for every hour
            allDaySlot={false}
            navLinks={true}
            selectLongPressDelay={0}
            height="auto"
            aspectRatio={1.35}
            dayCellDidMount={dayCellDidMount}
            eventDidMount={handleEventDidMount}
            eventStartEditable={false}
            eventResizableFromStart={false}
            eventDurationEditable={false}
            // you can update a remote database when these fire:
            // eventAdd={function(){}}
            //eventChange={function(){}}
            // eventRemove={function(){}}
            
          />
        </div>
      </div>
    )

}


  


  

export default LilacCalendar;
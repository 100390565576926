import React from 'react';

const AddressLink = ({ address, label }) => {
    const encodedAddress = encodeURIComponent(address);
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

    return (
        <a href={mapUrl} target="_blank" rel="noopener noreferrer">
        {label || address}
        </a>
    );
};

export default AddressLink;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from "axios";
import ResponsiveAppBar from '../../components/appbar/Appbar';
import LilacCalendar from '../../components/calendar/Calendar';
import { useParams } from 'react-router-dom';
import { db } from '../../components/auth/firebase';
import { doc, collection, getDoc } from 'firebase/firestore'; 
import { NailSalonFeature } from '../Map/nail_salons';
import { InstagramEmbed } from 'react-social-media-embed';
import { auth } from '../../components/auth/firebase';
import { Grid } from '@mui/material';
import IconRow from '../../components/iconrow/IconRow';

// Set the default configuration for Axios
// axios.defaults.withCredentials = true;




const ShopPage: React.FC = () => {

  const storePLaceHolder : NailSalonFeature = {
    type: 'Feature',
    id: '',
    geometry: {
      type: 'Point',
      coordinates: [0, 0]
    },
    properties: {
      name: 'placeholder',
      address: 'placeholder',
      instagram: ''
    }
    }
  
  const [shopInfo, setShopInfo] = useState<NailSalonFeature>(storePLaceHolder);
  const queryParams = new URLSearchParams(window.location.search);
  const shop_id = queryParams.get('shopID');

  let navigate = useNavigate(); 

  const getShopInfo = async () => {
    const shopsSnapshot = await getDoc(doc(db, "shops", shop_id));

    const shopData : NailSalonFeature = {
      type: 'Feature',
      id: shopsSnapshot.data().id,
      geometry: {
        type: 'Point',
        coordinates: [shopsSnapshot.data().geometry._long, shopsSnapshot.data().geometry._lat]
      },
      properties: {
        name: shopsSnapshot.data().name,
        address: shopsSnapshot.data().address,
        instagram: shopsSnapshot.data().instagram,
        facebook: shopsSnapshot.data().facebook,
        email: shopsSnapshot.data().email,
        phone: shopsSnapshot.data().phone,
      }
      }
    setShopInfo(shopData);
    console.log(shopInfo);
    }
  
  

  useEffect(() => {
    void getShopInfo();
  }, []);

  // if ((shopInfo.properties.instagram === '') || (shopInfo.properties.instagram === undefined)) {
  //   return (
  //     <div>
  //     <ResponsiveAppBar />
  //     <div className="lilac-calendar-container">
  //       <LilacCalendar shopId={shop_id}/>
  //     </div>      
  //   </div>
  //   );
  // }
  return (
    <div>
      <ResponsiveAppBar />
      
      <IconRow properties={shopInfo.properties}/>
      <div className="lilac-calendar-container">
        <LilacCalendar shopId={shop_id}/>
      </div>      
    </div>
  );

};

export default ShopPage;
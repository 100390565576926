import { db } from "../../components/auth/firebase";
import { doc, setDoc, getDoc, getDocs, collection } from 'firebase/firestore';
import { auth } from "../../components/auth/firebase";
import { getUserInfo } from "../usersDB/UsersDB";
import { ex } from "@fullcalendar/core/internal-common";


export interface ShopInfo {
    address: string;
    geometry: [];
    header_image: string;
    instagram: string;
    name: string;
    services: [];
    ShopData: ShopSubData;
}

export interface ShopSubData    {
    appointments: [];
    shopOwner: string;

}

// // Function to store user info
export async function storeShopInfo(userId: string, userInfo: object): Promise<void> {
    try {
        await setDoc(doc(db, "users", userId), userInfo);
        console.log("User information successfully stored!");
    } catch (error) {
        console.error("Error storing user information: ", error);
    }
    }


export async function getShopDataCollection(): Promise<ShopSubData | null> {
    try {
        const userData = await getUserInfo()

        const subcollectionRef = collection(db, 'shops', userData.shopOwned, 'ShopData');
        const subcollectionSnapshot = await getDocs(subcollectionRef);
        const subcollectionData = subcollectionSnapshot.docs.map(doc => ({
        ...doc.data()
        }));

        if (subcollectionData.length > 0){
            const shopSubData: ShopSubData = {
                appointments: subcollectionData[0].appointments,
                shopOwner: subcollectionData[0].shopOwner
            }
            return shopSubData;
        }
        console.error("Error getting user data collection information: ", "No data found");
        return null;
    } catch (error) {
        console.error("Error getting user data collection information: ", error);
        return null;
    }
}

// Function to get user info
export async function getShopInfo(): Promise<ShopInfo | null> {
    try {
        const userData = await getUserInfo()
        if (auth.currentUser) {
            const docSnap = await getDoc(doc(db, "shops", userData.shopOwned));
            const shopSubData = await getShopDataCollection();

            return {
                address: docSnap.data().address,
                geometry: docSnap.data().geometry,
                header_image: docSnap.data().header_image,
                instagram: docSnap.data().instagram,
                name: docSnap.data().name,
                services: docSnap.data().services,
                ShopData: {
                    appointments: shopSubData.appointments,
                    shopOwner: shopSubData.shopOwner
                }
            }
        }
    } catch (error) {
        console.error("Error getting shop information: ", error);
        return null;
    }
}
import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';


interface Shop {
    name: string;
    image: string;
    shopID: string;
    rating: number;
    distance: number;
}





const ShopCard: React.FC<Shop> = ( props: Shop ) => {
    const navigate = useNavigate();
    const name = props.name;
    const image = props.image;
    const shopID = props.shopID;
    const rating = props.rating;
    const distance = props.distance;
    

    const handleClick = (shopID: string) => {
    
    navigate(`/shop/search?shopID=${shopID}`);
}

  return (
    <Card sx={{ maxWidth: 300 }} onClick={()=> {handleClick(shopID)}}>
      <CardMedia component="img" height="140" image={image} alt={name} />
      <CardContent>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Rating: {rating}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Distance: {distance} km
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ShopCard;
